
































import { Component, Vue, Prop } from "vue-property-decorator";

// import { getModule } from "vuex-module-decorators";
// import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";

// const attendeeSearchStore = getModule(attendeeSearchVuexModule);

@Component({
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 150) + "...";
        }
    }
})
export default class WideCardLogoWithTitle extends Vue {
    activeBorder = "border-transparent";
    @Prop({ default: "row" })
    layout!: string;

    @Prop({
        default: ""
    })
    name!: string;

    @Prop({
        default: ""
    })
    imgPath!: string;

    @Prop({
        default: ""
    })
    id!: string;

    @Prop({
        default: ""
    })
    description!: string;

    get getLayoutOptions(): boolean {
        return this.$store.getters.layoutOptions;
    }
    get textHeader() {
        return this.$store.getters.getPageOptions("tradeshow").textHeader;
    }

    get deFaultFallbackLogo() {
        const logo = this.$store.getters.getPageOptions("tradeshow")
            .defaultLogo;

        return logo ? `${process.env.BASE_URL}bg/${logo}` : "";
    }

    get sectionToUse(): boolean {
        return this.$store.getters.getPageOptions("tradeshow").section
            .companyProfile;
    }

    routeToDetails() {
        if (!this.sectionToUse) return;
        this.$router.push({
            name: "ExhibitorDetails",
            params: {
                id: this.id
            }
        });
    }
}
