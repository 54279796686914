import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import {
    TradeshowCompanyObject,
    TradeshowCompaniesObject
} from "@/types/interfaces";
import apiSvc from "../../services/api";

@Module({
    dynamic: true,
    store: Store,
    name: "getTradeshowData",
    namespaced: true
})
export default class GetTradeshowDataModule extends VuexModule {
    tradeshowCompany: TradeshowCompanyObject = {};
    tradeshowCompanies: TradeshowCompaniesObject = {};

    get allTradeshowCompanies() {
        let all: Array<TradeshowCompanyObject> = [];

        for (const [key, value] of Object.entries(this.tradeshowCompanies)) {
            key;
            all = [...all, ...value];
        }

        return all.filter((item) => {
            return Boolean(item.companyName || item.name);
        });
    }

    @Mutation
    public setTradeshowCompany(data: TradeshowCompanyObject) {
        this.tradeshowCompany = data;
    }

    @Mutation
    public setTradeshowCompanies(data: TradeshowCompaniesObject) {
        this.tradeshowCompanies = data;
    }

    @Action({ commit: "setTradeshowCompanies" })
    async getTradeshowCompanies() {
        return await apiSvc
            .getTradeshowCompanies()
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            });
    }
}
