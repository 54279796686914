





















































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { v4 as uuidv4 } from "uuid";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import WideCardLogoWithTitle from "@/components/shared/WideCardLogoWithTitle.vue";

import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

const tradeshowStore = getModule(tradeshowVuexModule);
import "swiper/css/swiper.css";

@Component({
    components: {
        Swiper,
        SwiperSlide,
        WideCardLogoWithTitle
    }
})
export default class FeaturedExhibitorSwimlane extends Vue {
    isLoading = true;
    componentId = "";

    swiperOption = {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
            delay: 8000,
            disableOnInteraction: true
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 20
            },
            1024: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                spaceBetween: 40
            },
            1336: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 10
            }
        },
        navigation: {}
    };

    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
        this.componentId = uuidv4();

        // Options depending on componentId
        this.swiperOption.navigation = {
            nextEl: `.swiper-button-next_${this.componentId}`,
            prevEl: `.swiper-button-prev_${this.componentId}`
        };
    }
    get staticCompany() {
        if (this.tradeshowCompanies) {
            const com1 =
                "CAEI | Conf. of the Americas on International Education";
            const com2 = "Universidad Loyola Andalucia";
            const com3 = "Gothenburg";

            return this.tradeshowCompanies.filter((data: any) => {
                if (data.companyName) {
                    if (
                        data.companyName.toLowerCase() === com1.toLowerCase() ||
                        data.companyName.toLowerCase() === com2.toLowerCase() ||
                        data.companyName.toLowerCase() === com3.toLowerCase()
                    ) {
                        return data;
                    }
                }
            });
        }
        return [];
    }
    get tradeshowCompanies() {
        const companies = tradeshowStore.allTradeshowCompanies;

        return companies.sort((cur: any, nxt: any) => {
            if (cur.companyName) {
                return cur.companyName.localeCompare(nxt.companyName);
            } else return cur.name.localeCompare(nxt.name);
        });
        // return companies;
        // return Vue.prototype.MgSortByProperty(companies, "companyName");
    }

    get fallbackImage() {
        const logo = this.$store.getters.getPageOptions("tradeshow")
            .defaultLogo;

        return logo ? `${process.env.BASE_URL}bg/${logo}` : "";
    }
}
